<template>
  <b-form-group :label="`${filter.label} ${type==='like' ? 'contains' : 'equals'}`">
    <b-input-group
      class="input-group-merge"
    >
      <b-form-input
        v-model.trim="filter.value"
        type="text"
        size="sm"
      />
      <b-input-group-append is-text>
        <feather-icon
          v-if="filter.value !== null"
          icon="XIcon"
          class="cursor-pointer"
          @click="filter.value = null"
        />
      </b-input-group-append>
    </b-input-group>
  </b-form-group>
</template>

<script>
import {
  BFormInput, BInputGroup, BFormGroup, BInputGroupAppend,
} from 'bootstrap-vue'

export default {
  components: {
    BFormInput, BInputGroup, BFormGroup, BInputGroupAppend,
  },
  props: {
    type: String,
    filter: Object,
  },
}
</script>
