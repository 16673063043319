<template>
  <b-form-group
    :label="`${filter.label} in range`"
  >
    <div class="input-group input-group-sm">
      <flat-pickr
        v-model="dateRange"
        class="form-control"
        :config="pickrConfig"
        @input="onInput"
      />
      <div class="input-group-append">
        <b-button
          v-if="dateRange"
          variant="outline-primary"
          @click="onReset"
        >
          <feather-icon
            icon="XIcon"
            class="cursor-pointer"
          />
        </b-button>
      </div>
    </div>
  </b-form-group>
</template>

<script>
import {
  BFormGroup, BButton,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BButton,
    BFormGroup,
    flatPickr,
  },
  props: {
    type: String,
    filter: Object,
  },
  data() {
    return {
      from: null,
      to: null,
      dateRange: null,
      pickrConfig: { wrap: true, mode: 'range', dateFormat: 'Y-m-d' },
    }
  },
  methods: {
    onInput() {
      if (!this.dateRange) return

      const date = this.dateRange.split(' to ')
      if (date.length < 2) return
      this.from = date[0].concat(' 00:00:00')
      this.to = date[1].concat(' 23:59:59')
      this.$emit('input', [this.from, this.to])
    },
    onReset() {
      this.from = null
      this.to = null
      this.dateRange = null
      this.$emit('input', null)
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker";
</style>
